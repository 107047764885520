import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./login/saga"
import LayoutSaga from "./layout/saga"
import commonSaga from "./common/saga"
import ProfileSaga from "./profile/saga"
import projectUnitsSaga from "./projectUnits/saga"
import serviceRequestsSaga from "./serviceRequests/saga"
import billAndPaymentsSaga from "./bills&payment/saga"
import vendorProfilePreviewSaga from "./vendorProfilePreview/saga"
import dashboardSaga from "./dashboard/saga"
import manageUserSaga from "./manageUsers/saga"
import serviceTicketSaga from "./tickets/saga"
import VendorPaymentSaga from "./bills&payment/vendorFilter/saga"
import forgetPasswordSaga from "./forgetpwd/saga"
import proposalsSaga from "./proposal/saga"


export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(commonSaga),
    fork(ProfileSaga),
    fork(projectUnitsSaga),
    fork(serviceRequestsSaga),
    fork(billAndPaymentsSaga),
    fork(vendorProfilePreviewSaga),
    fork(dashboardSaga),
    fork(manageUserSaga),
    fork(serviceTicketSaga),
    fork(VendorPaymentSaga),
    fork(forgetPasswordSaga),
    fork(proposalsSaga),

  ])
}
