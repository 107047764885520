const API_URL = 'https://vipmalldemobackend.erpca.shop/public';
// const API_URL = `http://localhost:8000`
// const API_URL = `https://api.v1malls.com`

export const LOGIN = `${API_URL}/api/vendor-login`
export const LOGOUT_USER = `${API_URL}/api/vendor-logout`
export const UPDATE_PASS = `${API_URL}/api/update-vendor-password`
export const FORGET_PASSWORD = `${API_URL}/api/forget-password-vendor`
export const RESET_USER_PASSWORD = `${API_URL}/api/reset-vendor-password`

//COUNTRY STATE CITY API
export const GET_CITIES = `${API_URL}/api/get-city-vendor-data`
export const GET_STATES = `${API_URL}/api/get-state-vendor-data`
export const GET_COUNTRIES = `${API_URL}/api/get-country-vendor-data`

export const GET_PROJECTUNITS = `${API_URL}/api/show-all-client-project-unit`
export const GET_PROJECTUNIT_DETAIL = `${API_URL}/api/show-client-project-unit`
export const ADD_NEW_PROJECTUNIT = `${API_URL}`
export const DELETE_PROJECTUNIT = `${API_URL}`
export const UPDATE_PROJECTUNIT = `${API_URL}`

export const GET_PROPOSALS = `${API_URL}/api/list-vendor-proposal`
export const GET_PROPOSALS_TYPE = `${API_URL}/api/list-proposal-type`
export const GET_ACCEPTED_PROPOSAL = `${API_URL}/api/get-proposal_status`
export const GET_PROPOSAL_DETAIL = `${API_URL}/api/view-vendor-proposal`
export const ADD_NEW_PROPOSAL = `${API_URL}/api/add-vendor-proposal`
export const DELETE_PROPOSAL = `${API_URL}`
export const UPDATE_PROPOSAL = `${API_URL}`

export const GET_NEW_SERVICE_REQUESTS = `${API_URL}/api/list-service-requests-by-new-status`
export const GET_WIP_SERVICE_REQUESTS = `${API_URL}`
export const GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS = `${API_URL}/api/list-service-requests-by-sent-to-approval`
export const GET_COMPLETED_SERVICE_REQUESTS = `${API_URL}/api/list-service-requests-by-closed`
export const GET_SERVICE_REQUEST_DETAIL = `${API_URL}/api/get-service-requests-assigned-to-vendor`
export const UPDATE_SERVICE_REQUEST = `${API_URL}`

//bill and payment
export const GET_PENDING_BILL_LIST = `${API_URL}/api/list-pending-vendor-invoices`
export const GET_BILL_HISTORY_LIST = `${API_URL}/api/list-vendor-invoices`
export const GET_PROJECT_LIST = `${API_URL}/api/list-projects-by-vendor`
export const GET_TICKET_LIST = `${API_URL}/api/get-tickets`
export const GET_EXPENSE_LIST = `${API_URL}/api/list-expense-type`
export const GET_GST_RATE_LIST = `${API_URL}/api/list-gst-applicable-rate-vendor`
export const GET_PROJECT_COMPANY_DETAIL = `${API_URL}/api/get-project-company-and-location-details`
export const GET_TICKET_DETAIL = `${API_URL}/api/get-vendor-ticket-list`
export const ADD_NEW_INVOICE = `${API_URL}/api/add-vendor-invoices`
export const GET_DEBIT_NOTE_LIST = `${API_URL}/api/get-vendor-debit-note-list`

export const GET_LATEST_TICKET = `${API_URL}/api/latest-service-ticket-vendor`
export const GET_PREVIOUS_INVOICES_LIST = `${API_URL}/api/list-billing-with-components`
export const GET_SERVICE_REQUEST = `${API_URL}/api/`

export const GET_VENDOR_DETAIL = `${API_URL}/api/get-dashboard-vendor-data`

export const GET_USERS_LIST = `${API_URL}/api/list-all-vendor-users`
export const GET_USER_DETAIL = `${API_URL}/api/get-vendor-users`
export const ADD_NEW_USER = `${API_URL}/api/add-vendor-user`
export const UPDATE_USER = `${API_URL}/api/update-vendor-user`
export const DELETE_USER = `${API_URL}/api/delete-vendor-users`


//SERVICE REQUEST TICKETS in VENDOR
export const GET_SERVICE_TICKETS_LIST = `${API_URL}/api/list-service-request-tickets-vendor`
export const GET_SERVICE_TICKETS_DETAIL = `${API_URL}/api/get-vendor-service-request-ticket`


//DELETE SERVICE REQUEST TICKETS MANPOWER MATERIAL OTHER COST IN VENDOR
export const DELETE_MANPOWER = `${API_URL}/api/delete-vendor-manpower`
export const DELETE_MATERIAL = `${API_URL}/api/delete-vendor-material`
export const DELETE_OTHER_COST = `${API_URL}/api/delete-other-cost`

export const GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST = `${API_URL}/api/material-list`
export const GET_UNIT_MASTER_FOR_SERVICE_REQUEST = `${API_URL}/api/unit-list`

//add proposal, material othercost and manpower

export const ADD_MATERIAL_IN_TICKET = `${API_URL}/api/update-vendor-material`
export const ADD_MANPOWER_IN_TICKET = `${API_URL}/api/update-vendor-manpower`
export const ADD_OTHERCOST_IN_TICKET = `${API_URL}/api/update-vendor-other-cost`
export const SEND_FOR_REVIEW_IN_TICKET = `${API_URL}/api/vendor-resolution-file-upload`
export const UPDATE_STATUS_IN_TICKET = `${API_URL}/api/update-vendor-ticket-status`
export const UPLOAD_PROPOSAL = `${API_URL}/api/upload-vendor-proposal-file`

//vendor payment filter 
export const GET_PROJECT_FILTER = `${API_URL}/api/list-projects-for-vendor-invoice`
export const GET_COMPANY_FILTER = `${API_URL}/api/list-company-data-for-vendor-invoice`
