import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"
import { forgotPassword } from "../../helpers/backend"
import Swal from "sweetalert2"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      }})
    const response = yield call(forgotPassword,  {
      email_address: user.email,
    },"/forgot-password")
    if (response) {
      loadingSwal.close()
      yield put(
        userForgetPasswordSuccess(
          "Reset link are sent to your Registered Email"
        )
      )
    }
  } catch (error) {
    // yield put(userForgetPasswordError(error))
    loadingSwal.close()
    console.log(error.response.data.message)
    yield put(userForgetPasswordError(error.response.data.message))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
